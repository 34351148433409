import "core-js/modules/es.array.push.js";
import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.filter.js";
import ReportMixin from "@/mixins/Report";
import ReportPage from "@/components/ReportPage";
import MainTitle from "@/components/MainTitle.vue";
import AppTabs from "@/components/AppTabs.vue";
import DataTableActions from "../components/DataTable/DataTableActions.vue";
import DaysTable from "@/components/Product/DaysTable.vue";
import CategoriesPositionsTable from "@/components/Product/CategoriesPositionsTable.vue";
import KeywordsPositionsTable from "@/components/Product/KeywordsPositionsTable.vue"; //kwd
import SizesTable from "@/components/Product/SizesTable.vue";
import StoresTable from "@/components/Product/StoresTable.vue";

//&: 2025-02-16 sw->sf
import AdvYandexTable from "@/components/Product/AdvYandexTable.vue";
import SeoYandexTable from "@/components/Product/SeoYandexTable.vue";
import SeoGoogleTable from "@/components/Product/SeoGoogleTable.vue";
import ProductInfo from "@/components/Product/Info.vue";
import EntityCharts from "@/components/EntityCharts";
import { getEntityCellsConfig } from "@/utils/enums";
import ListAddItemButton from "@/components/List/AddItemButton.vue";
import SwitchInput from "@/components/SwitchInput.vue";
import LoadingSpinner from "@/components/Loading/Spinner";
const tableComponents = {
  days: DaysTable,
  categoriesPositions: CategoriesPositionsTable,
  sizes: SizesTable,
  stores: StoresTable,
  keywordsPositions: KeywordsPositionsTable,
  advYandex: AdvYandexTable,
  seoYandex: SeoYandexTable,
  seoGoogle: SeoGoogleTable
};
export default {
  mixins: [ReportMixin],
  meta() {
    var _this$product;
    const name = (_this$product = this.product) === null || _this$product === void 0 ? void 0 : _this$product.title;
    return {
      title: name ? `Обзор показателей товара ${name}` : undefined
    };
  },
  data() {
    let cells = getEntityCellsConfig("product");
    const customCells = {
      avgPerDay: {
        title: "Среднее<br>продаж в день",
        multi: [{
          id: "avg_sold",
          unit: cells.avg_sold.unit
        }, {
          id: "avg_revenue",
          unit: cells.avg_revenue.unit
        }],
        color: cells.avg_sold.color
      },
      losses: {
        color: cells.days.color,
        multi: [{
          id: "days",
          title: cells.days.title,
          unit: cells.days.unit
        }, {
          id: "losses",
          title: cells.losses.title,
          unit: cells.losses.unit
        }]
      }
    };
    delete cells.avg_sold;
    delete cells.avg_revenue;
    delete cells.days;
    delete cells.losses;

    //&:
    if (this.$route.params.mp === "wb") {
      delete cells.price;
      delete cells.price_old;
      delete cells.discount;
      delete cells.price_discount_spp; //wb-no-spp
    } else {
      //oz
      delete cells.price_spp;
      delete cells.price_discount_spp;
    }
    cells = {
      ...cells,
      ...customCells
    };
    return {
      product: null,
      tabs_tables_all: [{
        id: "days",
        title: "Детализация по дням"
      }, {
        id: "categoriesPositions",
        title: "Категории и позиции"
      }, {
        id: "sizes",
        title: "Размеры"
      },
      //store
      {
        id: "stores",
        title: "Склады"
      },
      //kwd
      {
        id: "keywordsPositions",
        title: "Запросы и позиции"
      },
      //sw->sf
      {
        id: "advYandex",
        title: "Платный трафик Яндекс"
      }, {
        id: "seoYandex",
        title: "SEO выдача Яндекс"
      }, {
        id: "seoGoogle",
        title: "SEO выдача Google"
      }],
      tabs_tables_model: null,
      cells,
      NA: "н/д",
      newTabs: [
        // {
        //     id: 3,
        //     title: "Сравнить товары",
        //     new: true,
        //     tabToShow: 1,
        // },
      ],
      activeTab: null,
      productSkuInput: false,
      newSku: "",
      productsToShow: [],
      newProductError: false,
      loadingNewProduct: false
    };
  },
  methods: {
    getTableComponent(id) {
      return tableComponents[id];
    },
    getTableId() {
      return this._uid;
    },
    async loadProduct() {
      this.product = await this.$store.dispatch("product/getProduct", {
        ...this.reportSettings
      });

      //console.log('296: this.product=',this.product)
    },
    loadOverview() {
      return this.$store.dispatch("product/productOverviewAll", this.reportSettings);
    },
    loadOverviewCompare(p_id) {
      return this.$store.dispatch("product/productOverviewAll", {
        ...this.reportSettings,
        p_id
      });
    },
    updateActiveTab(tab) {
      this.activeTab = tab;
    },
    showProductSkuInput() {
      this.productSkuInput = true;
    },
    async addProduct() {
      this.loadingNewProduct = true;
      try {
        const newProduct = await this.$store.dispatch("product/getProduct", {
          ...this.reportSettings,
          p_id: this.newSku
        });
        this.productSkuInput = false;
        this.newSku = "";
        this.productsToShow.push(newProduct);
      } catch (e) {
        console.error(e);
        this.newProductError = true;
      } finally {
        this.loadingNewProduct = false;
      }
    }
  },
  async mounted() {},
  computed: {
    addProductDisabled() {
      return !this.newSku;
    },
    tabs_tables() {
      return this.tabs_tables_all.filter(tab => {
        //console.log('345: tab=', tab)
        //console.log('346: this.product=', this.product)

        if (tab.id === "sizes") {
          var _this$product2;
          return (_this$product2 = this.product) === null || _this$product2 === void 0 ? void 0 : _this$product2.hasVariants;
        }
        //store :
        if (tab.id === "stores" && this.$route.params.mp !== "wb") {
          return false;
        }

        //sw->sf
        if (tab.id === "advYandex") {
          var _this$product3;
          return ((_this$product3 = this.product) === null || _this$product3 === void 0 ? void 0 : _this$product3.adv_1) > 0;
        }
        if (tab.id === "seoYandex") {
          var _this$product4;
          return ((_this$product4 = this.product) === null || _this$product4 === void 0 ? void 0 : _this$product4.seo_1) > 0;
        }
        if (tab.id === "seoGoogle") {
          var _this$product5;
          return ((_this$product5 = this.product) === null || _this$product5 === void 0 ? void 0 : _this$product5.seo_3) > 0;
        }
        return true;
      });
    },
    track() {
      return {
        url: this.$route.fullPath,
        type: `${this.$route.params.mp.toUpperCase()} / Анализ товара / ${this.tabs_tables_model.title}`,
        name: this.product.title,
        date: this.reportSettings.date,
        date2: this.reportSettings.date2
      };
    },
    fileName() {
      return `${this.$route.params.mp.toUpperCase()} Товар ${this.product.title} ${this.tabs_tables_model.title} ${this.reportSettings.date} ${this.reportSettings.date2}`;
    },
    reportSettings() {
      if (!this.calendar) {
        return null;
      }
      return {
        mp: this.$route.params.mp,
        p_id: this.$route.params.p_id,
        date: this.calendar[0],
        date2: this.calendar[1],
        fbs: this.fbs,
        ...this.$route.query
      };
    },
    productKey() {
      return `${this.$route.params.p_id}:${this.$route.params.mp}`;
    },
    tableKey() {
      return `${this.productKey}:${this.tabs_tables_model.id}`;
    }
  },
  watch: {
    reportSettings: {
      handler(v) {
        if (!v) {
          return;
        }
        this.loadProduct();
      },
      immediate: true
    },
    newSku() {
      this.newProductError = false;
    }
  },
  components: {
    MainTitle,
    AppTabs,
    DataTableActions,
    EntityCharts,
    ReportPage,
    ProductInfo,
    ListAddItemButton,
    SwitchInput,
    LoadingSpinner
  }
};