import "core-js/modules/es.object.has-own.js";
import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.filter.js";
import "core-js/modules/esnext.iterator.map.js";
import DataTable from "@/components/DataTable/DataTable";
export default {
  components: {
    DataTable
  },
  props: {
    id: {
      type: [String, Number],
      required: true
    },
    reportSettings: {
      type: Object,
      required: true
    }
  },
  methods: {
    tableLoader({
      query
    }) {
      return this.$store.dispatch("product/productSeoYandex", {
        ...query,
        ...this.reportSettings
      });
    }
  },
  computed: {
    /*
    stripSWUrl(url){
        //if(url.length<80)
        return url;
         // let a = url.split('&')
        // let s = ""
        // a.forEach(el=>let s =
         // a += '...';
    },
    */
    columns() {
      return [
      // ключевое слово / частотность / позиция / сниппет / УРЛ

      {
        name: "word",
        title: "Ключевое слово",
        type: "text",
        width: 160
      }, {
        name: "shows",
        title: "Точная частотность",
        width: 90
      }, {
        name: "shows1b",
        title: "Базовая частотность",
        width: 90
      }, {
        name: "shows1g",
        title: "Общая частотность",
        width: 90
      }, {
        name: "pos",
        title: "Позиция",
        type: "html",
        width: 60,
        sort: false
      }, {
        name: "snip",
        title: "Текст объявления",
        type: "html",
        width: 290,
        sort: false
      }, {
        name: "url",
        title: "URL",
        type: "link",
        width: 420,
        filter: "text",
        getLink(item) {
          return {
            href: item.url,
            //text: stripSWUrl(item.url),
            text: item.url.length > 100 ? item.url.substr(0, 100) + '...' : item.url
          };
        }
      }].map((it, i) => {
        if (!Object.hasOwn(it, 'show')) it.show = true;
        if (!Object.hasOwn(it, 'width')) it.width = 60;
        if (!Object.hasOwn(it, 'type')) it.type = "number";
        if ((it.type == "number" || it.type == "money") && !Object.hasOwn(it, 'filter')) it.filter = "numberRange";
        it.position = i + 1;
        return it;
      });
    }
  }
};