import "core-js/modules/es.object.has-own.js";
import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.filter.js";
import "core-js/modules/esnext.iterator.map.js";
import DataTable from "@/components/DataTable/DataTable";
//import { dateRangeDaysArray, dateFormat } from "@/utils/dates";

export default {
  components: {
    DataTable
  },
  props: {
    id: {
      type: [String, Number],
      required: true
    },
    reportSettings: {
      type: Object,
      required: true
    }
  },
  methods: {
    tableLoader({
      query
    }) {
      return this.$store.dispatch("product/productAdvYandex", {
        ...query,
        ...this.reportSettings
      });
    }
    /*
    stripSWUrl(url){
        if(url.length<80) return url;
         let a = url.split('&')
        let s = ""
        for(let i=0; i<a.length; i++){
            if(s.length+a[i].length > 80) break;
            s += '&'+a[i]
        }
        s += '...';
        return s;
    },
    */
  },
  computed: {
    columns() {
      /*
      const days = dateRangeDaysArray(
          this.reportSettings.date,
          this.reportSettings.date2
      );
      */

      return [
      /*
      {
          title: "Запрос",
          name: "keyword",
          show: true,
          width: 200,
          type: "link",
          noOverflow: true,
          getLink: (item) => {
              return {
                  route: {
                      name: "KeywordInfo",
                      params: {
                          k_id: item.k_id,
                      },
                      query: {
                          date: this.reportSettings.date,
                          date2: this.reportSettings.date2,
                          fbs: this.reportSettings.fbs,
                      },
                  },
                  text: item.keyword,
                  target: "_blank",
              };
          },
      },
      */

      //! ключевое слово / частотность / СРС / текст объявления / позиция объявления / УРЛ

      {
        name: "word",
        title: "Ключевое слово",
        type: "text",
        width: 160
      }, {
        name: "shows",
        title: "Точная частотность",
        width: 90
      }, {
        name: "shows1b",
        title: "Базовая частотность",
        width: 90
      }, {
        name: "shows1g",
        title: "Общая частотность",
        width: 90
      }, {
        name: "cpc",
        title: "Средний СРС",
        type: "money"
      }, {
        name: "snip",
        title: "Текст объявления",
        type: "html",
        width: 300,
        sort: false
      }, {
        name: "pos",
        title: "Позиция",
        type: "text",
        width: 60,
        sort: false
      }, {
        name: "url",
        title: "URL",
        type: "link",
        width: 420,
        filter: "text",
        getLink(item) {
          return {
            href: item.url,
            //text: this.stripSWUrl(item.url),
            text: item.url.length > 100 ? item.url.substr(0, 100) + '...' : item.url
          };
        }
      }].map((it, i) => {
        if (!Object.hasOwn(it, 'show')) it.show = true;
        if (!Object.hasOwn(it, 'width')) it.width = 60;
        if (!Object.hasOwn(it, 'type')) it.type = "number";
        if ((it.type == "number" || it.type == "money") && !Object.hasOwn(it, 'filter')) it.filter = "numberRange";
        it.position = i + 1;
        return it;
      });
    }
  }
};